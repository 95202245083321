import { Button } from '@klokgroep/shared-components/src/Button';
import { Caret, Icon, SearchIcon } from '@klokgroep/shared-components/src/Icons';
import {
  createHref,
  NavigationItem,
  MainNavigationMenu,
  SubNavigationItem,
  SITES,
  getCurrentEnvironmentHostNameForSiteId,
  SanityImageType,
} from '@klokgroep/sanity';
import { getSiteLogo } from '@klokgroep/shared-components/src/Logos';
import { LanguageToggle } from '@klokgroep/shared-components/src/LanguageToggle';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';
import { useRouter } from 'next/router';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import cx from 'classnames';
import Link from 'next/link';
import React, { Fragment, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './SiteHeader.module.css';
import { ContactPerson } from '@klokgroep/shared-components/src/SiteHeader/ContactPerson';

interface Properties {
  navigation?: MainNavigationMenu;
  contactPerson?: Omit<Sanity.Schema.SharedContactPerson, 'image'> & {
    image?: SanityImageType;
  };
}

const isSubMenu = (navItem: SubNavigationItem | NavigationItem): navItem is SubNavigationItem =>
  navItem._type === 'subMenu';

const MenuItem = (properties: NavigationItem) => {
  const { locale } = useSiteInfo();
  const router = useRouter();

  const hrefForType = useMemo(() => createHref({ ...properties, locale }), [locale, properties]);
  const { targetBlank, label } = properties;

  const isActive = useMemo(
    () => router.asPath.includes(hrefForType) && router.asPath !== '/' && !router.asPath.includes(`${hrefForType}/`),
    [hrefForType, router.asPath]
  );

  return (
    <li className={styles.menuItem}>
      <Link
        target={targetBlank ? '_blank' : undefined}
        className={cx(styles.navigationItem, styles.menuItemWithLink, {
          [styles.isActive]: isActive,
        })}
        href={hrefForType}>
        {label}
      </Link>
    </li>
  );
};

interface SubMenuItemProperties extends SubNavigationItem {
  setIsOpen: (_key: string | false) => void;
  level?: number;
  isOpen: boolean;
  extraClassName?: string;
}

const SubMenu = ({ label, items, _key, setIsOpen, isOpen, extraClassName, level = 1 }: SubMenuItemProperties) => {
  const [subMenuOpen, setSubMenuOpen] = useState<false | string>(false);
  const t = useCommonTranslations();

  const open: MouseEventHandler<HTMLElement> = useCallback(() => {
    setIsOpen(_key);
  }, [_key, setIsOpen]);

  const close: MouseEventHandler<HTMLElement> = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const className = useMemo(() => {
    if (styles[`submenuLevel${level as 1 | 2}`]) {
      return styles[`submenuLevel${level as 1 | 2}`];
    }
    return '';
  }, [level]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li onMouseEnter={open} onMouseLeave={close} className={cx(styles.menuItem)}>
      <button
        onClick={open}
        type="button"
        className={cx(styles.navigationItem, {
          [styles.isActive]: isOpen,
          [styles.openSubmenuButton]: !extraClassName,
          [styles[extraClassName as string]]: !!extraClassName,
        })}>
        {label}
        <div className={styles.arrowDown}>
          <Icon type="caretDown" />
        </div>
        <div className={styles.arrowRight}>
          <Caret />
        </div>
      </button>
      <ul className={cx(styles.submenu, className, { [styles.open]: isOpen, [styles.hideBoxShadow]: !!subMenuOpen })}>
        <li className={styles.backButton}>
          <button onClick={close} type="button">
            <Caret />
            {t('go_back')}
          </button>
        </li>
        {items?.map((item) =>
          isSubMenu(item) ? (
            <SubMenu
              isOpen={subMenuOpen === item._key}
              setIsOpen={setSubMenuOpen}
              key={item.label}
              level={level + 1}
              extraClassName="nestedSubmenuButton"
              {...item}
            />
          ) : (
            <MenuItem key={item.label} {...item} />
          )
        )}
      </ul>
    </li>
  );
};
export const SiteHeader = ({ navigation, contactPerson }: Properties) => {
  const { theme, siteId } = useSiteInfo();
  const router = useRouter();
  const t = useCommonTranslations();

  const [navigationOpen, setNavigationOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState<false | string>(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const Logo = useMemo(() => getSiteLogo(siteId), [siteId]);

  const toggleMenu = useCallback(() => {
    setSubMenuOpen(false);
    setNavigationOpen((previousMenuOpen) => !previousMenuOpen);
  }, []);

  const closeMenu = useCallback(() => {
    setNavigationOpen(false);
    setSubMenuOpen(false);
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', closeMenu);

    return () => {
      router.events.off('routeChangeStart', closeMenu);
    };
  }, [closeMenu, router.events]);

  const toggleSearch = useCallback(() => {
    setSearchOpen((previousSearchOpen) => !previousSearchOpen);
  }, []);

  const onSearch: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      const searchTerm = document.querySelector<HTMLInputElement>('#searchInput')?.value;
      router.push({ pathname: '/zoeken', query: { s: searchTerm } });
      toggleSearch();
    },
    [router, toggleSearch]
  );

  const locales = useMemo(
    () =>
      theme === 'novaform'
        ? SITES.filter(({ theme }) => theme === 'novaform').map((site) => ({
            title: site.companyName,
            locale: site.locale,
            href: getCurrentEnvironmentHostNameForSiteId(site.siteId),
          }))
        : undefined,
    [theme]
  );

  return (
    <Fragment>
      <div className={cx(styles.searchContainer, searchOpen ? styles.isVisible : undefined)}>
        <MaxWidth noMarginOverflow>
          <form onSubmit={onSearch} className={styles.searchForm}>
            <div className={styles.underline}>
              <span className={styles.searchIcon}>
                <SearchIcon />
              </span>
              <input id="searchInput" type="search" placeholder={t('menu_searchbar_input_placeholder')} />
              <input type="submit" value={t('menu_searchbar_submitbutton_value')} />
            </div>
          </form>
        </MaxWidth>
      </div>
      <header
        className={cx(styles.container, {
          [styles.containerActive]: navigationOpen,
          [styles.hasVisibleSearchBar]: searchOpen,
        })}>
        <MaxWidth noMarginOverflow>
          <div className={styles.containerInner}>
            <div className={styles.logoAndNavContainer}>
              <Link className={cx(styles.logo, { [styles.menuIsActive]: navigationOpen })} href="/">
                <Logo />
              </Link>

              <nav className={cx(styles.navigation, { [styles.navigationOpen]: navigationOpen })}>
                <ul className={cx(styles.mainNavigation)}>
                  {navigation?.items?.map((item) =>
                    isSubMenu(item) ? (
                      <SubMenu
                        isOpen={subMenuOpen === item._key}
                        setIsOpen={setSubMenuOpen}
                        key={item.label}
                        {...item}
                      />
                    ) : (
                      <MenuItem key={item.label} {...item} />
                    )
                  )}
                  {!!locales && locales.length > 1 && <LanguageToggle locales={locales} />}
                </ul>
              </nav>
            </div>

            <div className={styles.menuButtonsContainer}>
              <button className={styles.searchToggleButton} type="button" onClick={toggleSearch}>
                <SearchIcon />
                <span className="vh">{t('menu_button_search_open')}</span>
              </button>
              <div className={styles.mobileNavigationMenuToggle}>
                <Button
                  theme={navigationOpen && theme == 'holding' ? 'outlineInverted' : 'outline'}
                  onClick={toggleMenu}
                  icon={navigationOpen ? 'xmark' : 'hamburger'}>
                  {navigationOpen ? t('menu_button_close') : t('menu_button_open')}
                </Button>
              </div>
              {contactPerson && router.route !== '/vacatures/[slug]' ? (
                <ContactPerson contactPerson={contactPerson} />
              ) : undefined}
            </div>
          </div>
        </MaxWidth>
      </header>
    </Fragment>
  );
};
