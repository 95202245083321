import { MediaBlock, mediaQuery } from '../../corporateQueries';
import { ColumnsAndIconsBlock, blockWithColumnsAndIconsQuery } from './blockWithColumnsAndIcons';
import { ContentBlock, contentQuery } from './content';
import { TitleVideoBlock, titleVideoQuery } from './titleVideo';
import { JobApplicationProcessBlock, jobApplicationProcessQuery } from '../../queries/blocks/jobApplicationProcess';
import { CallToActionBlock, callToActionQuery } from '../../queries/blocks/callToAction';
import {
  infinityIllustrationWithRichTextQuery,
  InfinityIllustrationWithRichText,
} from './infinityIllustrationWithRichText';

export const getJobContentModulesQueries = () =>
  [
    contentQuery,
    infinityIllustrationWithRichTextQuery,
    titleVideoQuery,
    mediaQuery,
    blockWithColumnsAndIconsQuery,
    jobApplicationProcessQuery,
    callToActionQuery,
  ].join('');

export type MappedJobContentModules = Array<
  | ContentBlock
  | InfinityIllustrationWithRichText
  | TitleVideoBlock
  | ColumnsAndIconsBlock
  | JobApplicationProcessBlock
  | MediaBlock
  | CallToActionBlock
>;
