import { ReactNode } from 'react';
import cx from 'classnames';
import styles from './RichText.module.css';
import { BlockContainerWithBackgroundProperties } from '@klokgroep/shared-components/src/BlockContainerWithBackground';

interface Properties {
  children: ReactNode;
  large?: boolean;
  extraLarge?: boolean;
  backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
  preLine?: boolean;
  colorWhite?: boolean;
}

export const RichText = ({ children, large, extraLarge, backgroundColor, colorWhite, preLine }: Properties) => (
  <div
    className={cx(styles.container, {
      [styles.large]: large,
      [styles.extraLarge]: extraLarge,
      [styles.darkText]: backgroundColor !== undefined && backgroundColor !== 'black',
      [styles.lightText]: !backgroundColor || backgroundColor === 'black',
      [styles.colorWhite]: colorWhite,
      preLine: preLine,
    })}>
    {children}
  </div>
);
