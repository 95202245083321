import { groq } from 'next-sanity';

const NAME = 'infinityIllustrationWithRichText';

export const infinityIllustrationWithRichTextQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    selectedLayer,
    content,
    backgroundColor,
  },
`;

export type InfinityIllustrationWithRichText = Sanity.Keyed<Sanity.Schema.InfinityIllustrationWithRichText>;
