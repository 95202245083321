import { SiteIdType } from '@klokgroep/sanity';

export const KlokgroepLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#0C2950" viewBox="0 0 383 42">
    <path d="M75.9 18.51 90.1.68h8.52L83.06 20.22l17.5 20.21h-9.04L75.91 22.5v17.94h-6.82V.68h6.82v17.83ZM104.75.68h6.82v33.28h16.92v6.47h-23.74V.68ZM164.15 20.56c0-7.67-5.17-14.09-13.18-14.09-8 0-13.17 6.42-13.17 14.09 0 7.66 5.16 14.08 13.17 14.08 8 0 13.18-6.42 13.18-14.08Zm6.92 0c0 11.75-8.51 20.55-20.1 20.55-11.58 0-20.1-8.8-20.1-20.55C130.87 8.8 139.4 0 150.97 0c11.59 0 20.1 8.8 20.1 20.56ZM184.02 18.51 198.22.68h8.51l-15.56 19.54 17.5 20.21h-9.04L184.02 22.5v17.94h-6.82V.68h6.82v17.83ZM230.4 18.63h14.66v17.26s-5.28 5.22-15.62 5.22c-11.47 0-19.76-9.08-19.76-20.55S217.98 0 229.44 0c10.28 0 14.54 5.62 14.54 5.62l-3.4 3.35s-3.81-4.43-11.14-4.43c-8.63 0-14.65 7.56-14.65 16.02s6.02 16.01 14.65 16.01c7.27 0 10.9-2.9 10.9-2.9v-10.5h-9.93v-4.54M271.74 12.44c0-4.38-2.9-7.21-8.23-7.21h-5.85v14.42h5.85c5.34 0 8.23-2.84 8.23-7.21ZM252.9.68h10.34c7.89 0 13.28 4.66 13.28 11.76 0 9.42-8.57 11.18-9.7 11.35l11.01 16.64h-5.74L261.35 24.2h-3.69v16.24h-4.77V.68M315.98 20.56c0-8.46-5.85-16.02-14.54-16.02S286.9 12.1 286.9 20.56s5.85 16.01 14.54 16.01 14.54-7.55 14.54-16.01Zm5.1 0c0 11.47-8.17 20.55-19.64 20.55s-19.65-9.08-19.65-20.55S289.97 0 301.44 0c11.47 0 19.65 9.09 19.65 20.56ZM332.67 17.77h16.8v4.55h-16.8v13.57h18.51v4.54H327.9V.68h22.94v4.55h-18.17v12.54M368.78 20.1c5.4 0 9.09-2.38 9.09-7.44 0-5.05-3.7-7.43-9.09-7.43h-5.22V20.1h5.22Zm-10-19.42h10.29c8.29 0 13.57 4.38 13.57 11.98 0 7.61-5.28 11.99-13.57 11.99h-5.51v15.78h-4.77V.68ZM0 9.63V.68h44.27v8.95H0ZM22.13 24.76l22.14 17.12V30.57L22.13 13.45 0 30.57v11.31l22.13-17.12Z" />
  </svg>
);

export const VanDeKlokLogo = ({ inverted }: { inverted?: boolean }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 220 22">
    <path fill={inverted ? '#ffffff' : '#002E5C'} d="M23.2611.361942H0V5.12388h23.2611V.361942Z" />
    <path
      fill="#EC8F00"
      d="M43.4955 22.2413 34.156.361935h3.9096L43.6146 13.931 49.1076.361935h3.7274L43.4955 22.2413ZM58.8325.361935h3.6434L70.5892 21.5174h-3.6083L65.0682 16.5h-8.835l-1.9128 5.0174h-3.6082L58.8325.361935ZM57.5223 13.1787h6.2637l-3.1318-8.25354-3.1319 8.25354ZM76.2012.361935 86.6477 14.9316V.361935h3.5803V21.5174h-3.223L76.5585 6.98323V21.5174h-3.5802V.361935h3.2229ZM119.283 10.9432c0 6.1033-4.238 10.5813-10.32 10.5813h-6.74V.361935h6.74c6.089 0 10.32 4.470965 10.32 10.581265Zm-3.643 0c0-3.86772-2.564-7.13223-6.621-7.13223h-3.223V18.0755h3.223c4.057 0 6.621-3.2929 6.621-7.1323ZM126.087 8.94903h8.351V12.391h-8.351v5.6845h9.248v3.4419h-12.829V.361935h12.654V3.80387h-9.073v5.14516ZM149.957 9.85032l7.462-9.488385h4.477L153.72 10.7587l9.192 10.7587h-4.743l-8.205-9.5522v9.5522h-3.58V.361935h3.58V9.85032h-.007ZM165.112.361935h3.58V18.0755h8.891v3.4419h-12.471V.361935ZM199.962 10.9432c0 6.2594-4.477 10.9433-10.566 10.9433-6.088 0-10.565-4.6839-10.565-10.9433C178.831 4.68387 183.308 0 189.396 0c6.089 0 10.566 4.68387 10.566 10.9432Zm-3.644 0c0-4.08062-2.718-7.49417-6.922-7.49417s-6.922 3.41355-6.922 7.49417c0 4.0807 2.718 7.4942 6.922 7.4942s6.922-3.4135 6.922-7.4942ZM206.765 9.85032l7.462-9.488385h4.477L210.527 10.7587l9.193 10.7587h-4.744l-8.204-9.5522v9.5522h-3.58V.361935h3.58V9.85032h-.007Z"
    />
    <path fill="#EC8F00" d="m11.6306 13.1787 11.6305 9.1123v-6.0252L11.6306 7.16065 0 16.2658v6.0252l11.6306-9.1123Z" />
  </svg>
);

export const NovaformLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 220 26">
    <g fill="#fff" clipPath="url(#a)">
      <path d="M44.9723.943527 56.8571 17.4558V.943527h4.0732V24.9197h-3.6667L45.3789 8.44766V24.9197h-4.0732V.943527h3.6666ZM88.6216 12.9357c0 7.0939-5.0934 12.4023-12.0203 12.4023-6.9268 0-12.0202-5.3084-12.0202-12.4023 0-7.09398 5.1014-12.402367 12.0282-12.402367S88.6296 5.84172 88.6296 12.9357h-.008Zm-4.1449 0c0-4.62478-3.0927-8.49346-7.8754-8.49346-4.7826 0-7.8753 3.86868-7.8753 8.49346 0 4.6247 3.0927 8.4934 7.8753 8.4934 4.7827 0 7.8754-3.8687 7.8754-8.4934ZM99.7889 25.7401 89.1636.943527h4.4478l6.313 15.378273L106.174.943527h4.24L99.7889 25.7401ZM117.237.943527h4.145l9.231 23.976173h-4.105l-2.176-5.6864H114.28l-2.176 5.6864h-4.105L117.237.943527Zm-1.49 14.525673h7.126l-3.563-9.35402-3.563 9.35402ZM137.404 10.7077h9.334v3.9009h-9.334v10.3111h-4.073V.943527h14.085V4.85243h-10.012v5.85527ZM173.896 12.9357c0 7.0939-5.094 12.4023-12.021 12.4023-6.927 0-12.02-5.3084-12.02-12.4023 0-7.09398 5.093-12.402367 12.02-12.402367S173.896 5.84172 173.896 12.9357Zm-4.145 0c0-4.62478-3.093-8.49346-7.876-8.49346-4.782 0-7.875 3.86868-7.875 8.49346 0 4.6247 3.093 8.4934 7.875 8.4934 4.783 0 7.876-3.8687 7.876-8.4934ZM177.562.943527h7.804c4.718 0 7.875 3.080473 7.875 7.463913 0 3.59526-1.905 6.33786-5.293 7.19046l5.939 9.3138h-4.751l-5.636-9.0403h-1.865v9.0403h-4.073V.943527Zm11.542 7.463913c0-2.36464-1.626-3.56305-4.241-3.56305h-3.228v7.12611h3.228c2.615 0 4.241-1.1984 4.241-3.56306ZM201.116.943527 208.856 13l7.74-12.056473h3.77V24.9197h-4.073V8.82568l-7.437 11.40502-7.437-11.40502V24.9278h-4.073V.943527h3.77ZM26.4638.943535H0V6.34039h26.4638V.943535ZM13.2319 15.4692l13.2319 10.3272v-6.8285L13.2319 8.64874 0 18.9679v6.8285l13.2319-10.3272Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .533333h220v24.9333H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const HeilijgersLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="362.08" height="41.88" version="1.1" viewBox="0 0 362.08 41.88">
    <g transform="matrix(1.33333 0 0 -1.33333 0 41.88)">
      <g fillOpacity="1" fillRule="nonzero" stroke="none" transform="scale(.1)">
        <path
          fill="#0a9543"
          d="M696.633 309.023h51.117V10.906h-51.117v128.196H569.301V10.906h-51.106v298.117h51.106V187.645h127.332v121.378"
        />
        <path
          fill="#0a9543"
          d="M861.859 188.074h119.246V139.52H861.859V59.46h132.02V10.907H810.754v298.117H991.32v-48.554H861.859v-72.395"
        />
        <path fill="#0a9543" d="M1049.23 309.023h51.11V10.906h-51.11v298.117" />
        <path fill="#0a9543" d="M1169.31 309.023h51.1V59.461h126.92V10.906h-178.02v298.117" />
        <path fill="#0a9543" d="M1388.21 309.023h51.1V10.906h-51.1v298.117" />
        <path
          fill="#0a9543"
          d="M1616.03 309.023h51.1V104.598c0-62.598-38.75-98.797-101.77-98.797-59.63 0-87.31 49.82-87.31 49.82l37.47 31.516s18.74-32.793 49.84-32.793c39.17 0 50.67 25.554 50.67 48.554v206.125"
        />
        <path
          fill="#0a9543"
          d="M1929.89 67.977v63.027h-67.72v47.695h117.97V43.277s-37.9-37.476-110.3-37.476c-87.31 0-156.72 66.004-156.72 154.168 0 88.156 67.28 154.16 154.59 154.16 73.25 0 110.3-44.715 110.3-44.715l-34.92-34.074s-24.71 31.094-75.38 31.094c-60.05 0-102.64-48.973-102.64-106.465 0-57.5 44.72-106.477 104.77-106.477 42.59 0 60.05 14.485 60.05 14.485"
        />
        <path
          fill="#0a9543"
          d="M2084.89 188.074h119.24V139.52h-119.24V59.46h132.02V10.907h-183.13v298.117h180.57v-48.554h-129.46v-72.395"
        />
        <path
          fill="#0a9543"
          d="M2411.09 216.18c0 29.386-20.44 44.289-53.23 44.289h-40.46v-88.574h40.46c32.79 0 53.23 14.902 53.23 44.285zm-144.8 92.843h97.95c59.2 0 98.81-38.332 98.81-92.843 0-44.715-23.85-78.789-66.44-89.434l74.53-115.84h-59.62l-70.7 112.434h-23.42V10.906h-51.11v298.117"
        />
        <path
          fill="#0a9543"
          d="M2609.11 135.262c-43.86 15.332-93.27 39.179-93.27 94.543 0 42.168 37.48 84.324 97.52 84.324 62.18 0 97.1-47.274 97.1-47.274l-35.34-34.914s-24.71 33.645-61.76 33.645c-25.54 0-45.56-15.762-45.56-35.781 0-25.981 23.42-34.067 64.3-49.399 45.57-17.039 83.48-37.476 83.48-91.558 0-54.094-47.71-83.047-103.92-83.047-74.53 0-109.02 60.894-109.02 60.894l38.33 32.368s25.55-44.72 70.69-44.72c25.56 0 51.96 10.223 51.96 34.505 0 25.125-19.16 34.07-54.51 46.414"
        />
        <path fill="#0c2950" d="M0 241.859v67.118h331.988v-67.118H0" />
        <path fill="#0a9543" d="M165.996 128.363L331.988 0v84.848L165.996 213.215 0 84.848V0l165.996 128.363" />
      </g>
    </g>
  </svg>
);

export const AdriaanVanErkLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="541.787" height="41.213" version="1.1" viewBox="0 0 541.787 41.213">
    <g transform="matrix(1.33333 0 0 -1.33333 0 41.213)">
      <g fillOpacity="1" fillRule="nonzero" stroke="none" transform="scale(.1)">
        <path
          fill="#039adc"
          d="M586.34 128.52h89.433l-44.714 116.269zm18.73 180.566h51.965L772.875 10.98H721.34l-27.254 70.692H568.02L540.766 10.98h-51.524L605.07 309.086"
        />
        <path
          fill="#039adc"
          d="M998.566 160.043c0 54.512-36.621 100.496-94.539 100.496h-45.996V59.535h45.996c57.918 0 94.539 46.414 94.539 100.508zm51.964 0c0-86.035-60.479-149.063-147.354-149.063h-96.25v298.106h96.25c86.875 0 147.354-63.027 147.354-149.043"
        />
        <path
          fill="#039adc"
          d="M1232.79 216.254c0 29.383-20.44 44.285-53.24 44.285h-40.45v-88.582h40.45c32.8 0 53.24 14.902 53.24 44.297zm-144.8 92.832h97.95c59.2 0 98.81-38.32 98.81-92.832 0-44.727-23.85-78.789-66.44-89.434l74.53-115.84h-59.62l-70.7 112.43h-23.42V10.98h-51.11v298.106"
        />
        <path fill="#039adc" d="M1342.23 309.086h51.1V10.98h-51.1v298.106" />
        <path
          fill="#039adc"
          d="M1785.11 128.52l44.72 116.269 44.71-116.269zm-263.17 0l44.71 116.269 44.72-116.269zm333.86 180.566h-51.96l-105.6-271.793-105.61 271.793h-51.96L1424.84 10.98h51.52l27.26 70.692h126.06l27.26-70.691h82.59l27.26 70.69h126.07l27.25-70.69h51.53L1855.8 309.086"
        />
        <path
          fill="#039adc"
          d="M2051.69 309.086l149.06-205.266v205.266h51.1V10.98h-45.99L2056.8 215.825V10.98h-51.1v298.106h45.99"
        />
        <path fill="#039adc" d="M2525.67.766l-133.3 308.32h55.79l79.21-191.211 78.37 191.211h53.23L2525.67.766" />
        <path
          fill="#039adc"
          d="M2717.3 128.52h89.43l-44.71 116.269zm18.73 180.566h51.96L2903.83 10.98h-51.53l-27.26 70.692h-126.06l-27.26-70.691h-51.52l115.83 298.105"
        />
        <path
          fill="#039adc"
          d="M2975.36 309.086l149.06-205.266v205.266h51.1V10.98h-45.99l-149.06 204.844V10.98h-51.1v298.106h45.99"
        />
        <path
          fill="#039adc"
          d="M3397.8 188.148h119.24v-48.554H3397.8V59.535h132.02V10.981h-183.13v298.105h180.57v-48.547H3397.8v-72.391"
        />
        <path
          fill="#039adc"
          d="M3724 216.254c0 29.383-20.44 44.285-53.23 44.285h-40.46v-88.582h40.46c32.79 0 53.23 14.902 53.23 44.297zm-144.8 92.832h97.95c59.2 0 98.81-38.32 98.81-92.832 0-44.727-23.85-78.789-66.43-89.434l74.52-115.84h-59.62l-70.7 112.43h-23.42V10.98h-51.11v298.106"
        />
        <path
          fill="#039adc"
          d="M3878.58 175.363l106.47 133.723h63.88L3932.24 162.59l131.17-151.61h-67.71l-117.12 134.571V10.981h-51.11v298.105h51.11V175.363"
        />
        <path fill="#0c2950" d="M0 241.859v67.118h331.988v-67.118H0" />
        <path fill="#039adc" d="M165.996 128.363L331.988 0v84.848L165.996 213.215 0 84.848V0l165.996 128.363" />
      </g>
    </g>
  </svg>
);
export const AdriaanVanErkTimmerfabriekLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="541.787" height="41.213" version="1.1" viewBox="0 0 541.787 41.213">
    <g transform="matrix(1.33333 0 0 -1.33333 0 41.213)">
      <g fillOpacity="1" fillRule="nonzero" stroke="none" transform="scale(.1)">
        <path
          fill="#039adc"
          d="M586.34 128.52h89.433l-44.714 116.269zm18.73 180.566h51.965L772.875 10.98H721.34l-27.254 70.692H568.02L540.766 10.98h-51.524L605.07 309.086"
        />
        <path
          fill="#039adc"
          d="M998.566 160.043c0 54.512-36.621 100.496-94.539 100.496h-45.996V59.535h45.996c57.918 0 94.539 46.414 94.539 100.508zm51.964 0c0-86.035-60.479-149.063-147.354-149.063h-96.25v298.106h96.25c86.875 0 147.354-63.027 147.354-149.043"
        />
        <path
          fill="#039adc"
          d="M1232.79 216.254c0 29.383-20.44 44.285-53.24 44.285h-40.45v-88.582h40.45c32.8 0 53.24 14.902 53.24 44.297zm-144.8 92.832h97.95c59.2 0 98.81-38.32 98.81-92.832 0-44.727-23.85-78.789-66.44-89.434l74.53-115.84h-59.62l-70.7 112.43h-23.42V10.98h-51.11v298.106"
        />
        <path fill="#039adc" d="M1342.23 309.086h51.1V10.98h-51.1v298.106" />
        <path
          fill="#039adc"
          d="M1785.11 128.52l44.72 116.269 44.71-116.269zm-263.17 0l44.71 116.269 44.72-116.269zm333.86 180.566h-51.96l-105.6-271.793-105.61 271.793h-51.96L1424.84 10.98h51.52l27.26 70.692h126.06l27.26-70.691h82.59l27.26 70.69h126.07l27.25-70.69h51.53L1855.8 309.086"
        />
        <path
          fill="#039adc"
          d="M2051.69 309.086l149.06-205.266v205.266h51.1V10.98h-45.99L2056.8 215.825V10.98h-51.1v298.106h45.99"
        />
        <path fill="#039adc" d="M2525.67.766l-133.3 308.32h55.79l79.21-191.211 78.37 191.211h53.23L2525.67.766" />
        <path
          fill="#039adc"
          d="M2717.3 128.52h89.43l-44.71 116.269zm18.73 180.566h51.96L2903.83 10.98h-51.53l-27.26 70.692h-126.06l-27.26-70.691h-51.52l115.83 298.105"
        />
        <path
          fill="#039adc"
          d="M2975.36 309.086l149.06-205.266v205.266h51.1V10.98h-45.99l-149.06 204.844V10.98h-51.1v298.106h45.99"
        />
        <path
          fill="#039adc"
          d="M3397.8 188.148h119.24v-48.554H3397.8V59.535h132.02V10.981h-183.13v298.105h180.57v-48.547H3397.8v-72.391"
        />
        <path
          fill="#039adc"
          d="M3724 216.254c0 29.383-20.44 44.285-53.23 44.285h-40.46v-88.582h40.46c32.79 0 53.23 14.902 53.23 44.297zm-144.8 92.832h97.95c59.2 0 98.81-38.32 98.81-92.832 0-44.727-23.85-78.789-66.43-89.434l74.52-115.84h-59.62l-70.7 112.43h-23.42V10.98h-51.11v298.106"
        />
        <path
          fill="#039adc"
          d="M3878.58 175.363l106.47 133.723h63.88L3932.24 162.59l131.17-151.61h-67.71l-117.12 134.571V10.981h-51.11v298.105h51.11V175.363"
        />
        <path fill="#0c2950" d="M0 241.859v67.118h331.988v-67.118H0" />
        <path fill="#039adc" d="M165.996 128.363L331.988 0v84.848L165.996 213.215 0 84.848V0l165.996 128.363" />
      </g>
    </g>
  </svg>
);

export const HoldingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 377.947 377.947">
    <path
      d="M0 0h2834.65v2834.65H0V0"
      style={{ fill: '#0c2950', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
      transform="matrix(.13333 0 0 -.13333 0 377.947)"
    />
    <path
      d="M421.398 2323H2413.27v-402.63H421.398V2323M1417.33 1239.35l995.92-770.17v509.09l-995.92 770.17-995.951-770.17V469.18l995.951 770.17"
      style={{ fill: '#fff', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
      transform="matrix(.13333 0 0 -.13333 0 377.947)"
    />
  </svg>
);

export const VanDeKlokIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#E58937" />
    <g clipPath="url(#clip0_1760_5745)">
      <path d="M19.1998 5.28003H4.7998V8.19346H19.1998V5.28003Z" fill="white" />
      <path
        d="M11.9998 13.1129L19.1998 18.6771V14.9995L11.9998 9.4353L4.7998 14.9995V18.6771L11.9998 13.1129Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1760_5745">
        <rect width="14.4" height="14.4" fill="white" transform="translate(4.7998 4.80005)" />
      </clipPath>
    </defs>
  </svg>
);

export const NovaFormIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <rect width="24" height="24" fill="#C4332A" rx="4" />
    <g fill="white" clipPath="url(#clip0_1760_112)">
      <path d="M19.2 5.3H4.8v2.9h14.4v-3Z" />
      <path d="m12 13.1 7.2 5.6V15L12 9.4 4.8 15v3.7L12 13Z" />
    </g>
    <defs>
      <clipPath id="clip0_1760_112">
        <rect width="14.4" height="14.4" fill="white" transform="translate(4.8 4.8)" />
      </clipPath>
    </defs>
  </svg>
);

export const HeilijgersIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#44934D" />
    <g clipPath="url(#clip0_1760_5745)">
      <path d="M19.1998 5.28003H4.7998V8.19346H19.1998V5.28003Z" fill="white" />
      <path
        d="M11.9998 13.1129L19.1998 18.6771V14.9995L11.9998 9.4353L4.7998 14.9995V18.6771L11.9998 13.1129Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1760_5745">
        <rect width="14.4" height="14.4" fill="white" transform="translate(4.7998 4.80005)" />
      </clipPath>
    </defs>
  </svg>
);

export const VanErkIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#4799D7" />
    <g clipPath="url(#clip0_1760_5745)">
      <path d="M19.1998 5.28003H4.7998V8.19346H19.1998V5.28003Z" fill="white" />
      <path
        d="M11.9998 13.1129L19.1998 18.6771V14.9995L11.9998 9.4353L4.7998 14.9995V18.6771L11.9998 13.1129Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1760_5745">
        <rect width="14.4" height="14.4" fill="white" transform="translate(4.7998 4.80005)" />
      </clipPath>
    </defs>
  </svg>
);

export const getSiteLogo = (siteId: SiteIdType, inverted?: boolean) => {
  switch (siteId) {
    case 'vandeklok': {
      return inverted ? () => <VanDeKlokLogo inverted /> : VanDeKlokLogo;
    }
    case 'novaform': {
      return NovaformLogo;
    }
    case 'novaform-de': {
      return NovaformLogo;
    }
    case 'novaform-pl': {
      return NovaformLogo;
    }
    case 'heilijgers': {
      return HeilijgersLogo;
    }
    case 'vanerk': {
      return AdriaanVanErkLogo;
    }
    case 'vanerk-timmerfabriek': {
      return AdriaanVanErkTimmerfabriekLogo;
    }
    default: {
      return KlokgroepLogo;
    }
  }
};

export const getSiteIcon = (siteId: SiteIdType) => {
  switch (siteId) {
    case 'vandeklok': {
      return VanDeKlokIcon;
    }
    case 'novaform': {
      return NovaFormIcon;
    }
    case 'novaform-de': {
      return NovaFormIcon;
    }
    case 'novaform-pl': {
      return NovaFormIcon;
    }
    case 'heilijgers': {
      return HeilijgersIcon;
    }
    case 'vanerk': {
      return VanErkIcon;
    }
    case 'vanerk-timmerfabriek': {
      return VanErkIcon;
    }
    default: {
      return HoldingIcon;
    }
  }
};

export const getSiteWhiteLogo = (siteId: SiteIdType) => {
  switch (siteId) {
    case 'vandeklok': {
      return '/static/white-logos/vandeklok.png';
    }
    case 'novaform':
    case 'novaform-de':
    case 'novaform-pl': {
      return '/static/white-logos/novaform.png';
    }
    case 'heilijgers': {
      return '/static/white-logos/heilijgers.png';
    }
    case 'vanerk':
    case 'vanerk-timmerfabriek': {
      return '/static/white-logos/vanerk.png';
    }
    default: {
      return '/static/white-logos/klokgroep.png';
    }
  }
};
